<template>
    <header>
        <nav class="">
            <div class="branding">
                <span @click="toggleMobileNav" class="mt-2 mb-2  text-white fs-4"><i class="fa fa-bars toggle-btn"></i></span>
            </div>
        </nav>
        <transition name="mobile-nav">
            <ul class="mobile-nav overflow-scroll list-unstyled" v-show="mobileNav">
                <span @click="toggleMobileNav" class="m-2 px-2 mb-5 fs-4"><i class="fa fa-times toggle-btn2"></i></span>
                <li class="link  text-center py-3 px-2" @click="toggleMobileNav" v-if="user"><div class="d-flex flex-column text-center align-items-center justify-content-center"><i class="fa fa-user-circle fa-3x"></i><p> {{user.email}}</p> <p>{{user.branch}}</p></div></li>
                <li class="link" :class="this.$route.name=='home'?'bg-warning':''" @click="goToHome" ><i class="fa fa-shopping-bag"></i> Online Parcel</li>
                <li class="link" :class="this.$route.name=='Admin'?'bg-warning':''" @click="goToParcel" ><i class="fa fa-box"></i> Parcel</li>
                <li class="link" :class="this.$route.name=='CreateParcel'?'bg-warning':''" @click="goToCreate" v-show="user!==null && user.branch==='Maesot'"><i class="fa fa-plus-circle"></i> Add Parcel</li>
                <li class="link" :class="this.$route.name=='ParcelLists'?'bg-warning':''" @click="goToLists" v-if="user"><i class="fa fa-list" ></i> Parcels Lists</li>
                <li class="link" :class="this.$route.name=='about'?'bg-warning':''" @click="goToAbout"><i class="fa fa-info-circle" ></i> About</li>
                <li class="btn btn-dark mx-auto" v-show="user" @click="logOut" :to="{ name: 'Login' }"><i class="fa fa-sign-out-alt"></i> Sign out</li>
            </ul>
        </transition>
    </header>
</template>

<script>
    import firebase from "firebase/app";
    import "firebase/auth";
    export default {
        name: "navigation",
        data() {
            return {
                mobile: null,
                mobileNav: false,
            };
        },
        methods: {

            toggleMobileNav() {
                this.mobileNav = !this.mobileNav;
            },
            signOut() {
                firebase.auth().signOut();
                this.$router.push({name:"Home"})
            },
            goToHome(){
                this.$router.push({name:'home'});
                this.mobileNav = !this.mobileNav;
            },
            goToParcel(){
                this.$router.push({name:'Admin'});
                this.mobileNav = !this.mobileNav;
            },
            goToCreate(){
                this.$router.push({name:'CreateParcel'});
                this.mobileNav = !this.mobileNav;
            },
            goToLists(){
                this.$router.push({name:'ParcelLists'});
                this.mobileNav = !this.mobileNav;
            },
            goToAbout(){
                this.$router.push({name:'about'});
                this.mobileNav = !this.mobileNav;
            },
            logOut(){
                firebase.auth().signOut().then(()=>{
                  this.$router.push({name:'Login'});
                }).catch(error=>{
                  alert(error.message)
                });
            }

        },
        computed: {
            user() {
                return this.$store.state.user;
            },
        },
    };
</script>

<style lang="scss" scoped>
header {
  background-color: #FFC107;
  padding: 15px 30px;
  position: fixed;
  width: 100%;
  z-index: 99;
  .toggle-btn{
    color: #2c3e50;
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer ;
    transition: 0.8s;
  }
  .toggle-btn:hover, .toggle-btn:focus{
    background-color: white;
    transform: scale(0.9);
  }


  .mobile-nav {
    width: 70%;
    padding: 10px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 20000;
    box-shadow: 0 0 10px #cdcaca;
    height: 100%;
    background-color: #ffffff;
    top:0;
    left: 0;
    .link {
      cursor: pointer;
      text-decoration: none;
      background-color: #f3f3f3;
      color: #36383F;
      margin-bottom: 9px;
      border-radius: 20px;
      padding: 10px 10px;
      transition: 0.8s;
    }
    .link:hover {
      font-weight: bold;
      background-color: #FFC107;
    }
    .btn{
      width: 80%;
      position: relative;
      align-items: center;
      top:50px;
      margin-bottom: 10px;
      border-radius: 20px !important;
    }
    .toggle-btn2{
      color: white;
      background-color: #2c3e50;
      width: 40px;
      height: 40px;
      padding: 10px;
      border-radius: 50%;
      cursor: pointer ;
      transition: 0.5s;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .toggle-btn2:hover, .toggle-btn:focus{
      transform: scale(0.9);
    }

  }
  .mobile-nav-enter-active,
  .mobile-nav-leave-active {
    transition:0.3s;
  }
  .mobile-nav-enter {
    transform: translateX(-250px);
  }
  .mobile-nav-enter-to {
    transform: translateX(0);
  }
  .mobile-nav-leave-to {
    transform: translateX(-250px);
  }
}
</style>