<template>
    <div class="loading">
        <div class="card bg-white p-4 text-center">
            <span></span>
          <p class="mt-2 fw-bold">Loading...</p>

        </div>
    </div>
</template>

<script>
    export default {
        name:"loading",
    }
</script>

<style scoped>
    .card{
      border-radius: 20px;
      width: 200px;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .loading {
        top: 0;
        z-index: 1000;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    span {
        border: 10px solid #f3f3f3;
        border-radius: 50%;
        border-top: 10px solid #fbc520;
        border-bottom: 10px solid #fbc520;
        width: 60px;
        height: 60px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin ease 1000ms  infinite;
    }
    /* Safari */
    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>