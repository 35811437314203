<template>
  <div class="sticky">
    <a href="https://www.facebook.com/LiNEOs1313/" target="_blank">
      <img src="@/assets/facebook1.png">
    </a>
  </div> 
</template>

<script>
export default {
  name: 'Sticky'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sticky {
  position: fixed;
  z-index: 10;
  width: 60px;
  height: 60px;
  right: 56px;
  bottom:75px;
  text-align: center;
  padding: 7px;
  cursor: pointer;
  display: block;
}

@media screen and (max-width: 600px) {
  .sticky {
    position: fixed;
    z-index: 10;
    width: 50px;
    height: 60px;
    right: 28px;
    bottom: 19px;
    text-align: center;
    padding: 7px;
    cursor: pointer;
    display: block;
  }
}

.sticky img {
  width: 120%;
}

</style>