<template>
  <footer class="footer">
    <span>LINE Group &copy; 2021 Powered by LightIdea</span>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.footer {
  text-align: center;
  position: fixed;
  bottom: 0;
  height: 50px;
  padding: 10px 10px;
  font-size: 12px;
  width: 100%;
  background: #ffffff;
}
@media screen and (max-width: 600px) {
  .footer {
    text-align: center;
    position: fixed;
    bottom: 0;
    height: 30px;
    font-size: 12px;
    width: 100%;
    padding: 10px 10px;
    background: #ffffff;
  }
}
</style>