<template>
  <div >
    <Loading v-if="loadingActive"/>
    <InfoModal v-if="modalActive" :modal-message="modalMessage" v-on:close-modal="closeModal"/>
    <div class="">
      <div class="container-fluid bg-warning text-center">
        <img class="mt-5 pt-5" width="200" src="@/assets/logo.png">
        <p class="fs-3 fw-bolder text-white my-3">Online Parcel</p>
      </div>
      <div class="container">
        <div class="row" >
          <div class="col-12 col-md-8 col-lg-8 mx-auto">
            <form class="form mt-2" autocomplete="off">
              <div class="row justify-content-center align-items-center">

                <div class="col-12 col-md-6 col-lg-6">
                  <select class="form-select form-select-lg mb-3" v-model="searchAttribute"  aria-label="default select example">
                    <option value="" selected disabled >Search by </option>
                    <option v-for="(attr , index) in searchAttributes" v-bind:key="index" >
                      {{attr}}
                    </option>
                  </select>
                </div>

                <div class="col-12 col-md-6 col-lg-6">
                  <input type="text" name="text" id="search-input" required :disabled="disableInput" class="form-control form-control-lg" v-model="search" :placeholder="inputPlaceholder"><br>
                </div>

                <div class="col-12 text-center">
                  <p v-if="(this.error === true)" style="color: red; font-size: 12px;">{{errorMessage}}</p>
                  <button @click.prevent="searchItems" class=" w-50 mx-auto mt-2 btn btn-warning btn-lg text-white"><i class="fa fa-search"></i> Search</button>
                </div>
              </div>
              <br>
            </form>
          </div>
        </div>

        <div class="row mb-5 pb-5" v-if="parcelLists.length>0">
          <h3 class="text-center my-2">Parcel Lists</h3>
          <div class="col-12 col-md-10 col-lg-10 mx-auto">
            <div class="accordion " role="tablist">
              <b-card no-body class="mb-2 m-0 p-0" v-for="(parcel, index) in parcelLists" :key="index">
                <b-card-header header-tag="header" class="table-responsive" role="tab">
                  <table class="table">
                    <tr class="align-middle">
                      <td>#</td>
                      <th>Name</th>
                      <th>Ms Code</th>
                      <th>Order No.</th>
                      <th>Status</th>
                      <th>Current City</th>
                      <th>Group</th>
                      <th>Details</th>
                    </tr>
                    <tr class="align-middle">
                      <td>{{index+1}}</td>
                      <td>{{parcel.parcelName}}</td>
                      <td>{{parcel.parcelMSCode}}</td>
                      <td>{{parcel.parcelOrderNumber}}</td>
                      <td class="align-middle">
                        <span class="badge rounded-pill" :class="parcel.parcelCity===parcel.parcelUpdatedCity ? 'bg-success':'bg-warning'">
                          {{parcel.parcelCity===parcel.parcelUpdatedCity?"Arrived":"On going"}}
                        </span>
                      </td>
                      <td>{{parcel.parcelUpdatedCity!==''?parcel.parcelUpdatedCity:parcel.parcelBranch}}</td>

                      <td>{{parcel.parcelGroup? parcel.parcelGroup:'-'}}</td>
                      <td colspan="4">
                        <b-button variant="outline-dark" class="w-100" block v-b-toggle="'accordian-'+parcel.parcelID"><i class="fa fa-chevron-down"></i></b-button>
                      </td>
                    </tr>
                  </table>
                </b-card-header>
                <b-collapse :id="'accordian-'+parcel.parcelID" accordion="my-accordion" role="tabpanel">
                  <b-card-body class="table-responsive">
                    <table class="table-striped mx-auto">
                      <thead></thead>
                      <tbody>
                      <tr>
                        <td>Status</td>
                        <td>
                      <span class="badge rounded-pill" :class="parcel.parcelCity===parcel.parcelUpdatedCity ? 'bg-success':'bg-warning'">
                        {{parcel.parcelCity===parcel.parcelUpdatedCity?"Arrived":"On going"}}
                      </span>
                        </td>

                      </tr>
                      <tr>
                        <td>Name</td>
                        <td>: {{parcel.parcelName}}</td>
                      </tr>
                      <tr>
                        <td>Phone</td>
                        <td>: {{parcel.parcelPhone}}</td>
                      </tr>
                      <tr>
                        <td>Order Number</td>
                        <td>
                          : {{parcel.parcelOrderNumber}}
                        </td>
                      </tr>
                      <tr>
                        <td>MS Code</td>
                        <td>: {{parcel.parcelMSCode}}</td>
                      </tr>
                      <tr>
                        <td>From</td>
                        <td>: {{parcel.parcelBranch}}</td>
                      </tr>
                      <tr>
                        <td>Current City</td>
                        <td>: {{parcel.parcelUpdatedCity?parcel.parcelUpdatedCity:parcel.parcelBranch}}</td>
                      </tr>
                      <tr>
                        <td>To</td>
                        <td>: {{parcel.parcelCity}}</td>
                      </tr>

                      <tr>
                        <td>Date</td>
                        <td class="text-wrap">
                          : {{parcel.parcelUpdatedDate.toDate().toLocaleDateString('en-US')}}
                        </td>
                      </tr>
                      </tbody>
                    </table>

                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>


           <!-- <div class="shadow-sm rounded mb-3">
              <div class="d-flex justify-content-between align-items-center">

                <button class="btn btn-primary" type="button" data-toggle="collapse" :data-target="'collapse'+parcel.parcelID" aria-expanded="false" :aria-controls="'collapse'+parcel.parcelID">
                  <i class="fa fa-angle-down"></i>
                </button>
              </div>

              <div class="collapse" :id="'collapse'+parcel.parcelID">
                <div class="card card-body table-responsive">

                </div>
              </div>

            </div>-->

<!--          <ParcelCards :parcel="parcel" v-for="(parcel, index) in parcelLists" :key="index"/>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import db from "../firebaseConfig";
import Loading from "../components/Loading";
import InfoModal from "../components/InfoModal";
// import ParcelCards from "../components/ParcelCards";

export default {
  components: {InfoModal, Loading},
  // components: {ParcelCards},
  data() {
    return {
      search: '',
      query:'',
      error:true,
      errorMessage:"",
      searchAttribute:"",
      loadingActive:false,
      modalActive:false,
      modalMessage:"",
      searchAttributes:[
        "Phone",
        "Order Number",
        "MS Code"
      ]
    }
  },
  computed:{
    parcelLists(){
      return this.$store.state.parcelLists;
    },


    inputPlaceholder(){
      switch (this.searchAttribute) {
        case "Order Number":
          return "Enter your orderNumber"
        case "Phone":
          return "Enter your Phone"
        case "MS Code":
          return "Enter your MS Code"
        default:
          return "Choose something"
      }
    },
    disableInput(){
      if(this.searchAttribute){
        return false;
      }
      return true;
    }
  },
  methods: {
    searchItems() {
      this.loadingActive = true;
      switch (this.searchAttribute) {
        case "Order Number":
          this.query= "orderNumber";
          break;
        case "Phone":
          this.query= "phone";
          break;
        case "MS Code":
          this.query= "msCode";
          break;
        default:
          this.query= null;
      }
      if(this.search !== '') {
        this.$store.state.parcelLists = [];
        db.collection("parcelLists")
                .where(this.query, "==", this.search)
                .get()
                .then((querySnapshot) => {
                  this.loadingActive = false;
                  if(querySnapshot.size !== 0){
                    querySnapshot.forEach(doc => {
                      if (!this.$store.state.parcelLists.some(parcel => parcel.parcelID === doc.id)) {
                        const data = {
                          parcelBranch: doc.data().branch,
                          parcelCity: doc.data().to,
                          parcelUpdatedCity: doc.data().changedCity,
                          parcelID: doc.data().data,
                          parcelDate: doc.data().updatedAt,
                          parcelName: doc.data().name,
                          parcelOrderNumber: doc.data().orderNumber,
                          parcelPhone: doc.data().phone,
                          parcelGroup: doc.data().group,
                          parcelMSCode: doc.data().msCode,
                          parcelUserID: doc.data().userId,
                          parcelUpdatedDate: doc.data().updatedAt,
                        };
                        this.$store.state.parcelLists.push(data);
                      }
                    });
                    this.loadingActive = false;
                    this.searchAttribute = "";
                    this.search = "";
                  }
                  else {
                    this.loadingActive=false;
                    this.modalActive=true;
                    this.modalMessage ="Parcel does not exist!"
                  }

                }).catch(err=>{alert(err.message)});
      }else {
        this.loadingActive = false;
        this.modalActive=true;
        this.modalMessage ="Please Fill input!"
      }

    },
    closeModal(){
      this.modalActive = !this.modalActive;
    }
  }
}
</script>

<style scoped>
input, select, button, option{
  border-radius: 20px !important;
}
table{
  border-radius: 20px !important;
}
td:first-child{
  text-align: start;
}
td, th{
  border: 2px solid #eae9e9;
  border-radius: 20px !important;
  margin: 3px;
  padding: 5px;
  white-space: nowrap;
}
  /*@media screen and (min-width: 600px) {
    .accordion{
      width: 500px !important;
    }
}*/
</style>
