<template>
    <div class="modal">
        <div class="card rounded shadow-lg">
            <div class="d-flex flex-column card-body text-center justify-content-center align-items-center">
                <i class="fa fa-info-circle fs-3 text-primary "></i>
                <p class="fs-6 fw-bolder my-3">{{this.modalMessage}}</p>
                <button id="close_button" @click="closeModal" class="btn btn-dark">Close</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"InfoModal",
        props:["modalMessage"],
        methods:{
            closeModal(){
                this.$emit("close-modal")
            }
        },
        mounted() {
            window.document.getElementById("close_button").focus();
        }
    }
</script>

<style scoped>
    .modal{
        top:0;
        z-index: 999999;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,0.2);
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .card{
        border-radius: 20px !important;
        width: 300px;
        height: 300px;
    }
    button{
        width: 150px;
        border-radius: 20px !important;
    }
</style>