<template>
    <div >
        <div class="home">
            <img class="img-fluid mt-5 pt-5" width="300" src="@/assets/logo.png">
        </div>
        <form class="form searchbox container mt-5" @submit.prevent="search" autocomplete="off">
            <div class="form-group">
                <input type="text" name="text" class="form-control search" v-model="parcelID" placeholder="Enter your parcel code here....."><br>
                <span v-if="(this.errmsg === true)" style="color: red; font-size: 12px;">Please Enter Your Parcel Code!</span>
                <span v-if="(this.invalidID === true)" style="color: red; font-size: 12px;">Your Parcel Code is not Valid!</span>
                <button type="submit" name="submit" class="form-control submit">Search</button>
            </div>
            <br>
        </form>
        <div id="mobileshow">
            <h5 @click="openCamera" class="text-center mt-5"><i class="fa fa-qrcode"></i> Scan with QR Code</h5>
        </div>
    </div>
</template>

<script>

    import db from "../../firebaseConfig";

    export default {
        name:"AdminHome",
        data() {
            return {
                parcelID: '',
                errmsg: false,
                invalidID: false
            }
        },
        methods: {
            search() {
                if (this.parcelID !== '') {
                  db.collection("parcel").doc(this.parcelID)
                        .get()
                        .then((doc) => {
                            if (doc.exists) {
                                this.$router.push({
                                    name: 'parcelDetails',
                                    params: { parcelid: this.parcelID }
                                });
                                this.parcelID = '';
                                this.invalidID = false;
                            }
                            else {
                                this.invalidID = true;
                            }
                        }).catch((error) => {
                        console.log(error)
                    })
                    this.errmsg = false;
                }
                else {
                    this.errmsg = true;
                    this.invalidID = false;
                }
            },
            openCamera () {
                this.$router.push({
                    name: 'scanner'
                })
            }
        }
    }
</script>

<style scoped>

    input::placeholder {
        color: #fbc520;
    }

    .home {
        text-align: center;
        position: relative;
        height: 300px;
        background: #fbc520;
        border-bottom-left-radius: 50% 20%;
        border-bottom-right-radius: 50% 20%;
    }

    .home img {
        /* padding-top: 0px; */
        padding-bottom: 100px;
    }

    #mobileshow {
        display:none;
    }

    @media screen and (max-width: 768px) {
        .home {
            text-align: center;
            position: relative;
            height: 280px;
            background: #fbc520;
            border-bottom-left-radius: 50% 20%;
            border-bottom-right-radius: 50% 20%;
        }

        .home img {
            padding-top: 50px;
            padding-bottom: 50px;
            width: 50%;
        }

        #mobileshow {
            display:block;
            color: #fbc520;
        }
    }
    p {
        margin-bottom: 20px;
        cursor: pointer;
    }

    .loading-indicator {
        font-weight: bold;
        font-size: 2rem;
        text-align: center;
    }

    @media screen and (max-width: 600px) {
        .home {
            text-align: center;
            position: relative;
            height: 200px;
            background: #fbc520;
            border-bottom-left-radius: 50% 20%;
            border-bottom-right-radius: 50% 20%;
        }

        .home img {
            padding-top: 50px;
            padding-bottom: 50px;
            width: 50%;
        }

        #mobileshow {
            display:block;
            color: #fbc520;
        }
    }

    .searchbox {
        text-align: center;
    }

    .searchbox input {
        margin-left: auto;
        margin-right: auto;
        width: 60%;
        height: 50px;
        border: 1px solid #808080;
        background-color: #ffffff;
        border-radius: 12px;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    .searchbox button {
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        padding: 15px 20px;
        width: 30%;
        height: 50px;
        background-color: #fbc520;
        color: #ffffff;
        border-radius: 50px;
        border: none;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: bold;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    @media screen and (max-width: 768px) {
        .searchbox input {
            width: 100%;
            height: 50px;
            background-color: #ffffff;
            border: 2px solid #808080;
            border-radius: 12px;
        }

        .searchbox button {
            margin-top: 20px;
            padding: 15px 20px;
            width: 70%;
            height: 50px;
            background-color: #fbc520;
            color: #ffffff;
            border-radius: 50px;
            border: none;
            cursor: pointer;
            text-transform: uppercase;
            font-weight: bold;
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        }
    }

</style>
