import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import AdminHome from '../views/AdminView/AdminHome'
import firebase from 'firebase/app'
import"firebase/auth"
import ErrorDisplay from "../views/ErrorDisplay";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta:{
      title:"Home",
      requiresAuth: false,
    }
  },
  {
    path: '/onlineParcel',
    name: 'Admin',
    component: AdminHome,
    meta:{
      title:"Admin",
      requiresAuth: false,
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import( /* webpackChunkName: "About" */ '../views/About.vue'),
    meta:{
      title:"Home",
      requiresAuth: false,
    }
  },
  {
    path:'/admin',
    name:'Login',
    component: () => import( /* webpackChunkName: "About" */ '../views/authentication/Login'),
    meta:{
      title:"Login",
      requiresAuth: false,
    }
  },
  {
    path:'/admin/forgot-password',
    name:'ForgotPassword',
    component: ()=>import(/* webpackChunkName: "About" */ '../views/authentication/ForgotPassword'),
    meta:{
      title:"ForgotPassword",
      requiresAuth: false,
    }
  },
  {
    path:'/create-parcel',
    name:'CreateParcel',
    component: ()=>import(/* webpackChunkName:"CreateParcel" */ '../views/AdminView/CreateParcel'),
    meta:{
      title:"Create Parcel",
      requiresAuth: true,
    }
  },
  {
    path: '/parcel/:parcelid',
    name: 'parcelDetails',
    component: () => import( /* webpackChunkName: "ParcelDetail" */ '../views/ParcelDetails.vue'),
    meta:{
      title:"Parcel Details",
      requiresAuth: false,
    }
  },
  {
    path:'/parcel-group/:parcelID',
    name:'ParcelGroupDetails',
    props:true,
    component: ()=>import(/* webpackChunkName: "ParcelDetail" */ '../views/AdminView/ParcelGroupDetails'),
    meta:{
      title:"Parcel Group Details",
      requiresAuth: true,
    }
  },
  {
    path:'/update-parcel/:parcelID',
    name:'UpdateParcel',
    props:true,
    component: ()=>import(/* webpackChunkName: "UpdateParcel" */ '../views/AdminView/UpdateParcel'),
    meta:{
      title:"Update",
      requiresAuth: true,
    }
  },
  {
    path:'/detail/:parcelID',
    name:'AllParcelGroupDetails',
    props:true,
    component: ()=>import(/* webpackChunkName: "ParcelDetail" */ '../views/AdminView/AllParcelDetails'),
    meta:{
      title:"All Parcel Group Details",
      requiresAuth: true,
    }
  },
  {
    path:'/parcel-lists',
    name:'ParcelLists',
    component: ()=>import(/* webpackChunkName: "ParcelDetail" */ '../views/AdminView/ParcelTable'),
    meta:{
      title:"Parcel Lists",
      requiresAuth: true,
    }
  },
  {
    path: '/scanner',
    name: 'scanner',
    component: () => import( /* webpackChunkName: "ParcelDetail" */ '../views/QR.vue')
  },
  {
    path: '/error/:error',
    name: 'ErrorDisplay',
    props:true,
    component: ErrorDisplay
  },
  {
    path: '*',
    component: () => import( /* webpackChunkName: "404" */ '../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to,from,next)=>{
  document.title = `${to.meta.title} | Line Group`;
  next();
})

router.beforeEach(async (to, from, next)=>{
  let user = await firebase.auth().currentUser;
  if(to.matched.some((res)=>res.meta.requiresAuth)){
    if(user){
      return next();
    }
    return next({name:"ErrorDisplay"});
  }
  return next();
})

export default router