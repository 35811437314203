import Vue from "vue";
import Vuex from "vuex"
import firebase from "firebase/app";
import  db from "../firebaseConfig";
import "firebase/auth";

Vue.use(Vuex);
export default new Vuex.Store({
    state:{
        //user
        user:[],
        userEmail:null,
        userName:null,
        userId:null,
        userOrder:null,
        userBranch:null,

        //when myawaddy choose in parcelTable page
        selection:null,
        //parcel
        sameGroupListsForAll:[],
        parcelLists:[],
        todayParcelLists:[],
        parcelByPhoneLists:[],
        updateParcel:null,

        parcelBranch:null,
        parcelUpdatedCity:null,
        parcelID:null,
        parcelDate:null,
        parcelUpdatedDate:null,
        parcelGroup:null,
        parcelMSCode:null,
        parcelName:null,
        parcelOrderNumber:null,
        parcelFilterDate:null,
        parcelPhone:null,
        parcelCity:null,
        parcelUserID:null,

        todayParcelListsLoaded:null,
        allParcelListsLoaded:null,

        //cities
        cities:[],
    },
    getters:{
    },
    mutations:{

        updateUser(state, payload){
            state.user = payload;
        },

        setUserDetails(state, doc){
            state.userId = doc.data().uid;
            state.userBranch = doc.data().branch;
        },
        setSelection(state,payload){
            state.selection = payload;
        },

        updateParcelID(state, payload){
            state.parcelID = payload;
        },
        updateParcelCity(state, payload){
            state.parcelCity = payload;
        },
        updateParcelName(state, payload){
            state.parcelName = payload;
        },
        updateParcelOrderNumber(state, payload){
            state.parcelOrderNumber = payload;
        },
        updateParcelPhone(state, payload){
            state.parcelPhone = payload;
        },
        updateParcelGroup(state, payload){
            state.parcelGroup = payload;
        },
        updateParcelMSCode(state, payload){
            state.parcelMSCode = payload;
        },
        updateParcelUpdatedCity(state,payload){
            state.parcelUpdatedCity = payload;
        },
        updateParcelFilterDate(state,payload){
            state.parcelFilterDate = payload;
        }
    },
    actions:{
            //Retrieving signed in user branch a and committing to the state userid and branch through mutation
         getCurrentUser({ state }) {
                db.collection("account").doc(firebase.auth().currentUser.uid).get().then(accountResult=>{
                    state.user=({...accountResult.data(), "email":firebase.auth().currentUser.email})
                    // console.log(state.user)
                }).catch(err=>{
                    throw(err.message)
                });
            },

        //Call from UpdateParcel
        getParcelByID({state}, payload){
            db.collection("parcelLists").doc(payload).get()
                .then((doc)=>{
                    state.parcelBranch=doc.data().branch,
                        state.parcelCity=doc.data().to,
                        state.parcelID=doc.data().data,
                        state.parcelDate=doc.data().date,
                        state.parcelName=doc.data().name,
                        state.parcelOrderNumber=doc.data().orderNumber,
                        state.parcelPhone=doc.data().phone,
                        state.parcelGroup=doc.data().group,
                        state.parcelMSCode=doc.data().msCode,
                        state.parcelUserID=doc.data().userId,
                        state.parcelFilterDate = doc.data().filterDate,
                        state.parcelUpdatedDate = doc.data().updatedAt,
                        state.parcelUpdatedCity=doc.data().changedCity
                }).then(()=>{

            }).catch(err=>{
                throw(err.message);
            });
        },

        //Retrieving all the cities from the serviceCity and pushing to the cities array
        getCities({state}){
            db.collection("serviceCity").doc("city").get().then(queryResult=>{
                state.cities=queryResult.data().city;
            });
        },

        //Parcel Deleting call from Update Parcel
        async deleteParcel({ commit }, payload) {
            await db.collection("parcelLists")
                .doc(payload)
                .collection('record')
                .get()
                .then(querySnapshot =>{
                    querySnapshot.forEach(doc=>{
                        doc.ref.delete();
                    });
                    (async()=>{
                        const getParcel = await db.collection("parcelLists").doc(payload);
                        await getParcel.delete();
                        const record = await db.collection("parcelLists").doc(payload).collection('record').doc();
                        await record.delete();
                        commit("filterDeletedParcel", payload);
                        commit("filterTodayDeletedParcel", payload);
                    })()

                }).catch((err)=>{
                throw(err.message)
            })

        },

    },
    modules:{

    }
});