// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from "firebase/app";
import "firebase/firestore";
var firebaseConfig = {
  apiKey: "AIzaSyCUVGUtd6O0PpDGyA614x4bfuIN5DAdidQ",
  authDomain: "line-group-app.firebaseapp.com",
  projectId: "line-group-app",
  storageBucket: "line-group-app.appspot.com",
  messagingSenderId: "1051035416765",
  appId: "1:1051035416765:web:b1eeeee35a50adba931fd4",
  measurementId: "G-L23FNXJ78Q"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
// firebaseApp.firestore().enablePersistence();
const timestamp = firebase.firestore.FieldValue.serverTimestamp();
export {timestamp};
// export default firebaseApp.firestore();
// export default firebase.initializeApp(firebaseConfig);
export default firebaseApp.firestore();