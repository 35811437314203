<template>
  <div id="app" >

    <!--<Header v-show="navigation"/>-->
    <Navigation/>
    <router-view />
    <Sticky />
    <Footer />
  </div>
</template>

<script>
import Footer from './components/Footer.vue'
import Sticky from './components/Sticky.vue'
import firebase from 'firebase/app';
import "firebase/auth";
// import Header from "./components/Header";
import Navigation from "./components/Navigation";
export default {
  name: 'App',
  components: {
    Navigation,
    Footer,
    Sticky
  },
  data(){
    return{
      navigation:null
    }
  },
  created() {
    firebase.auth().onAuthStateChanged((user)=>{
      this.$store.commit("updateUser", user);
      if(user){
        this.$store.dispatch("getCurrentUser", user);
        this.$store.dispatch("getCities");
      }
    });
    // this.checkRoute();
  },
  mounted() {

  },
  methods:{
    /*checkRoute(){
      if(this.$route.name === "Login" ){
        this.navigation = false;
      }
      this.navigation = true;
    }*/
  }
}
</script>

<style lang="scss">
  @import "assets/styles/custom";
  @import '../node_modules/bootstrap/scss/bootstrap.scss';
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;900&display=swap');
  #app {
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
</style>
