<template>
  <div class="container">
   <div class="row">
     <div class="col-12 col-lg-6 mt-5 pt-5 mx-auto text-center">
       <h4 class="alert alert-warning">Oops! There is an error:</h4>
       <p class="fs4">{{error?error:'Page Not Found'}}</p>
     </div>
   </div>
  </div>
</template>

<script>
export default {
  props:['error']
}
</script>

<style scoped>

</style>