import Vue from 'vue'
import App from './App.vue'
import router from './router'
import firebase from "firebase/app";
import { BootstrapVue } from 'bootstrap-vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min"
import '@fortawesome/fontawesome-free/css/all.css'
import { firestorePlugin } from 'vuefire'
import VueQrcodeReader from 'vue-qrcode-reader'
import store from './store'
import VCalendar from 'v-calendar';

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});

Vue.use(VueQrcodeReader)
Vue.use(BootstrapVue)
Vue.use(firestorePlugin)

Vue.config.productionTip = false;
let app;
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
/*
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')*/
